import { useUserStore } from 'store/user.store';
import Loader from 'shared/utils/loaderHelper.util';
import Notificator from 'shared/services/notificator.service';
import ModalManager from 'shared/services/modalManager.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { DocumentsApiService } from 'services/api/documentsApi.service';
import { IUpdRegistryUnfilledUpd } from 'models/supplier/supplierUpdRegistry.model';

export function useSupplierUnfilledUpdRegistry() {
    const userStore = useUserStore();
    const loadingUnfilledUpd = Loader.getReactiveInstance();
    const modalManager = inject<ModalManager>(ModalManager.getServiceName());

    const dateNow = new Date();
    const unfilledUpd = ref<IUpdRegistryUnfilledUpd>();
    const deadlineFillUpdDate = ref<Date | undefined>();

    function isAllowUpdRegistry(): boolean {
        return UserHelper.isSupplier && SupplierHelper.isQualificationCompleted && userStore.isSupplierAdmin;
    }

    function isNeedWarningByDate(): boolean {
        return dateNow.getDay() > 4 && dateNow.getDay() < 21;
    }

    async function checkUnfilledUpd(isNeedWarningModal = false): Promise<void> {
        if (!isAllowUpdRegistry() || !isNeedWarningByDate()) {
            return;
        }

        loadingUnfilledUpd.activate();
        try {
            unfilledUpd.value = await DocumentsApiService.getUpdRegistryUnfilledUpd(userStore.supplierId);
            if (unfilledUpd.value?.count) {
                deadlineFillUpdDate.value = new Date(dateNow.getFullYear(), dateNow.getMonth(), 20);
                isNeedWarningModal && (await showWarningModal());
            }
        } catch (error) {
            clientSentry.captureException(error);
            Notificator.showDetachedNotification('Произошла ошибка при запросе незаполненных УПД');
        } finally {
            loadingUnfilledUpd.deactivate();
        }
    }

    async function showWarningModal(): Promise<void> {
        if (
          !deadlineFillUpdDate.value ||
          !await modalManager?.showConfirmModal(
            'Заполните реестр УПД',
            `Необходимо заполнить реестр УПД до<br>${DateFormat.default(deadlineFillUpdDate.value)}`,
            'Заполнить',
            'Позже',
            { contentTextClass: 'mm-fs-14px' },
          )
        ) {
            return;
        }

        await navigateTo('/supplier/documents/upd-registry/');
    }

    return {
        unfilledUpd,
        checkUnfilledUpd,
        deadlineFillUpdDate,
    };
}
