import {
  IUpdRegistryHistory,
  IUpdRegistryActualHistory,
  IUpdRegistryUnfilledUpd,
} from 'models/supplier/supplierUpdRegistry.model';

export class DocumentsApiService {
    public static async getUpdRegistryTemplate(supplierId: number): Promise<IUpdRegistryHistory> {
      return AuthHelper.fetch<IUpdRegistryHistory>(`/api/v1/upd_registry/${supplierId}/template`, {
          method: 'GET',
      });
    }

    public static async getUpdRegistryActualHistory(supplierId: number): Promise<IUpdRegistryActualHistory> {
      return AuthHelper.fetch<IUpdRegistryActualHistory>(`/api/v1/supplier_upd_registry_history/${supplierId}/actual`, {
          method: 'GET',
      });
    }

    public static async getUpdRegistryUnfilledUpd(supplierId: number): Promise<IUpdRegistryUnfilledUpd> {
      return AuthHelper.fetch<IUpdRegistryUnfilledUpd>(`/api/v1/mp_upd_registry/${supplierId}/unfilledCount`, {
          method: 'GET',
      });
    }

    public static async uploadUpdRegistry(supplierId: number, formData: FormData): Promise<IUpdRegistryHistory> {
      return AuthHelper.fetch<IUpdRegistryHistory>(`/api/v1/supplier_upd_registry/${supplierId}`, {
          method: 'PUT',
          body: formData,
      });
    }
}
